import { defineStore } from 'pinia';
import { useAuthStore } from './auth';
import { useCartStore } from './cart';

export const useProductStore = defineStore('product', {
    state: () => ({
        isProduct: true,

        product: null as any,
        group: null as any,

        subtype: null as any,

        subtypes: [] as any[],

        selectedModifiers: {} as any,
        selectedTabModifiers: {} as any,
        selectedRequiredModifiers: {} as any
    }),

    getters: {
        isSimpleProduct: (state) => {
            return state.product && !Object.values(state.product.modifiers_groups).length;
        },

        isComboProduct: (state) => {
            return state.product?.combo && state.product?.combo != null;
        },

        sizes: (state) => {
            return state.group.products.filter((el: any) => el.subtype == state.subtype);
        },

        price: (state) => {
            if (!state.product) {
                return null;
            }

            var modsPrice = 0;

            Object.keys(state.selectedModifiers).forEach((key: any) => {
                state.selectedModifiers[key].forEach((mod: any) => {
                    modsPrice += mod.product.price;
                });
            });

            var reqPrices = [] as any;
            var reqTotal = 0;

            if (state.product.show_required_modifiers_prices === true) {
                Object.keys(state.selectedRequiredModifiers).forEach((key: any) => {
                    state.selectedRequiredModifiers[key].forEach((mod: any) => {
                        let price = mod.product.price;

                        if (mod.product.add_price_as_modifier != null) {
                            price = mod.product.add_price_as_modifier;
                        }
                        reqPrices.push(price);
                        reqTotal += price;
                    });
                });
            }

            if (state.product.combo_cheapest_mod_free_amount > 0 && reqPrices.length > 0) {
                var minReqPrice = reqPrices[0];

                reqPrices.forEach((p: any) => {
                    if (p < minReqPrice) {
                        minReqPrice = p;
                    }
                });

                reqTotal -= minReqPrice;
            }

            return state.product.price + modsPrice + reqTotal;
        },
    },

    actions: {
        addModifier(group: any, modifiers: any) {
            this.selectedModifiers[group.id] = modifiers;
            this.selectedModifiers = { ...this.selectedModifiers };
        },

        addTabModifier(group: any, modifier: any) {
            this.selectedTabModifiers[group.id] = modifier;
        },

        addRequiredModifier(group: any, modifiers: any) {
            this.selectedRequiredModifiers[group.id] = modifiers;
        },

        async addToCart() {
            const cartStore = useCartStore();

            if (cartStore.loading) {
                return;
            }

            const authStore = useAuthStore();

            if (!(await authStore.validateAddress())) {
                return false;
            }

            await cartStore.addToCart(this.product, this.selectedModifiers, this.selectedTabModifiers, this.selectedRequiredModifiers).then((result) => {
                if (result) {
                    this.clear();
                }
            });

            return true;
        },

        initWithProduct(value: any) {
            this.product = value;
            this.isProduct = true;

            this.subtype = null;
            this.subtypes = [];
        },

        initWithGroup(value: any) {
            this.group = value;
            this.isProduct = false;

            this.subtypes = this.group.products.map((el: any) => el.subtype)
                .filter((value: any, index: number, array: any[]) => array.indexOf(value) === index);
            this.subtype = this.subtypes[0];
            this.product = this.sizes[0];
        },

        clear() {
            this.isProduct = true;
            this.product = null;
            this.group = null;
            this.subtypes = [];
            this.selectedModifiers = {};
            this.selectedTabModifiers = {};
            this.selectedRequiredModifiers = {};
        }
    },
});